<template>
	<v-navigation-drawer
		v-model="showMenu"
		width="320"
		disable-resize-watcher
		disable-route-watcher
		location="right"
		temporary
	>
		<div class="tabmenu-wrapper">
			<div class="menu-control">
				<a
					v-if="props.activeSubcategory"
					href="javascript:void(0)"
					class="flex items-center"
					@click="() => showSubcategory(null)"
				>
					<Icon name="md-chevron_left" />
					<span class="inline-block">Back</span>
				</a>
				<a
					v-show="props.activeSubmenu && !props.activeSubcategory"
					href="javascript:void(0)"
					class="flex items-center"
					@click="() => showSubmenu(null)"
				>
					<Icon name="md-chevron_left" />
					<span class="inline-block">Back</span>
				</a>
				<Icon
					class="ml-auto"
					name="close"
					@click="toggleMenu"
				/>
			</div>
			<div
				v-if="!props.activeSubmenu && !props.activeSubcategory"
				class="menu"
			>
				<h1 class="title">{{ $t("components.navigations.label.menu") }}</h1>
				<div class="items-wrapper">
					<a
						v-for="(item, index) in navItems.slice(0, 4)"
						:key="index"
						class="menu-item"
						href="javascript:void(0)"
						@click="() => handleMenuItem(item.id)"
					>
						<Icon
							v-if="item.icon"
							class="ml-[16px]"
							:name="item.icon"
						/>

						<p class="label">{{ $t(item.label) }}</p>
						<Icon
							v-if="item.hasSubmenu"
							class="mr-[16px]"
							name="md-chevron_right"
						/>
					</a>
				</div>
			</div>
			<div v-else-if="props.activeSubmenu">
				<Submenu :name="props.activeSubmenu" />
			</div>
		</div>
		<template #append>
			<div
				v-if="!props.activeSubmenu && !props.activeSubcategory && navItems.slice(4).length > 0"
				class="items-wrapper"
			>
				<a
					v-for="(item, index) in navItems.slice(4)"
					:key="index"
					class="menu-item"
					href="javascript:void(0)"
					@click="() => handleNavItem(item.id)"
				>
					<Icon
						v-if="item.icon"
						class="ml-[16px]"
						:name="item.icon"
					/>
					<p class="label">{{ $t(item.label) }}</p>
					<Icon
						v-if="item.hasSubmenu"
						class="mr-[16px]"
						name="md-chevron_right"
					/>
				</a>
			</div>
			<div class="items-wrapper remove_border">
				<a
					v-for="(item, index) in accountItems"
					:key="index"
					class="menu-item border-b-[1px] border-neutral-100"
					href="javascript:void(0)"
					@click="handleAccountNav(item.id)"
				>
					<p class="label">{{ $t(item.label) }}</p>
					<Icon
						v-if="item.icon"
						class="mr-[15px]"
						:name="item.icon"
					/>
				</a>
			</div>
			<NavFooter class="footernote" />
		</template>
	</v-navigation-drawer>
</template>

<script lang="ts" setup>
import $bus from "../../bus";
import NavFooter from "@/components/Navigation/NavFooter.vue";
import Submenu from "@/components/TngvNavigation/Submenu/index.vue";
import Icon from "@/components/Icon/index.vue";

const { organizationProfile } = useOrgStore();
const userStore = useUserStore();
const { user, isLoggedIn } = storeToRefs(userStore);
const $router = useRouter();
const { clearCartData } = useCartStore();

interface TabMenuProps {
	activeSubmenu: string | null;
	activeSubcategory: string | null;
}
const props = defineProps<TabMenuProps>();

const { routes } = useNavigations();
const navItems = computed(() =>
	routes
		.filter(item => item.showOnTabbar === false)
		.filter(item => {
			if (item.required_auth) {
				return isLoggedIn.value;
			} else {
				return true;
			}
		})
);

const emits = defineEmits<{
	(e: "toggleSubmenu", value: string | null): void;
	(e: "toggleSubcategory", value: string | null): void;
}>();

const showSubmenu = (name: string | null) => emits("toggleSubmenu", name);
const showSubcategory = (name: string | null) => emits("toggleSubcategory", name);

const showMenu = ref(false);
const toggleMenu = () => {
	showMenu.value = !showMenu.value;
};

const handleMenuItem = (id: string) => {
	switch (id) {
		case "about-us":
			toggleMenu();
			$router.push("/" + organizationProfile?.slug + "/about-us");
			break;
		case "contact":
			toggleMenu();
			$router.push("/" + organizationProfile?.slug + "/contact");
			break;
		default:
			showSubmenu(id);
			break;
	}
};

const handleNavItem = (id: string) => {
	if (id === "my-account" && !user.value) {
		// user not logged in
		showSubmenu(null);
		toggleMenu();
	} else {
		// user logged in
		showSubmenu(id);
	}
};

onMounted(() => {
	$bus.on("toggleMenu", toggleMenu);
});

const accountItems = computed(() =>
	isLoggedIn.value
		? [
				{
					id: "go_to_my_admin",
					icon: "md-open_in_new",
					label: "components.navigations.label.go_to_my_admin",
					showBadge: false,
					path: "",
					showOnTabbar: false,
					hasSubmenu: false,
				},
				{
					id: "sign_out",
					icon: "md-logout",
					label: "components.navigations.label.sign_out",
					showBadge: false,
					path: "",
					showOnTabbar: false,
					hasSubmenu: false,
				},
		  ]
		: [
				{
					id: "login",
					icon: undefined,
					label: "components.navigations.label.login",
					showBadge: false,
					path: "",
					showOnTabbar: false,
					hasSubmenu: false,
				},
				{
					id: "create_account",
					icon: undefined,
					label: "components.navigations.label.create_account",
					showBadge: false,
					path: "",
					showOnTabbar: false,
					hasSubmenu: false,
				},
		  ]
);

const handleAccountNav = (id: string) => {
	toggleMenu(); // close drawer
	switch (id) {
		case "login":
			$router.push(`/auth`);
			break;
		case "create_account":
			$router.push("/auth?tab=sign_up&accountType=business");
			break;
		case "go_to_my_admin":
			$router.push("https://system.tengiva.com/dashboard");
			break;
		case "sign_out":
			userStore.userLogout();
			clearCartData();
			$router.push(organizationProfile?.slug ? `/${organizationProfile.slug}` : "");
			break;
		default:
			break;
	}
};
</script>

<style lang="scss" scoped>
.tabmenu-wrapper {
	@apply pt-[16px] h-auto;
	.menu-control {
		@apply px-[16px] flex justify-between items-center leading-[36px] min-h-[36px];
		.icon {
			@apply cursor-pointer self-center;
		}
	}
	.profile {
		@apply flex items-center justify-start pl-[16px] pr-[32px] cursor-pointer;
	}
	.menu {
		.title {
			@apply text-[24px] leading-[40px] font-dm-serif my-[20px] ml-[16px];
		}
	}
}

.items-wrapper {
	@apply border-t-[1px] border-b-[1px] border-neutral-100 divide-y-[1px] divide-neutral-100;
	.menu-item {
		@apply flex py-[7px] items-center;
		.label {
			@apply pl-[16px] flex-grow;
		}
	}
}

.remove_border {
	@apply border-b-0 divide-y-0;
}

.footernote {
	@apply h-[50px] my-[15px];
}
</style>
