<template>
	<v-menu
		v-if="isLoggedIn"
		offset="24"
	>
		<template #activator="{ props }">
			<div
				v-bind="props"
				class="flex items-center desktop-nav-item cursor-pointer"
			>
				<Icon
					name="md-account_circle"
					class="mr-2"
				/>
				{{ $t("components.navigations.label.my_account") }}
				<Icon name="md-expand_more" />
			</div>
		</template>
		<v-card
			v-if="userName"
			min-width="300"
		>
			<v-list>
				<v-list-item
					id="personal_info"
					:title="`${userName}`"
					:subtitle="`${userOrganizationProfile?.public_name}` ?? ''"
				>
					<template #prepend>
						<v-avatar
							class="ml-[8px] mr-[4px]"
							color="black"
							size="40"
						>
							{{ userInitial }}
						</v-avatar>
					</template>
				</v-list-item>
			</v-list>

			<v-divider
				color="#E6E6EB"
				opacity="100"
			/>

			<v-list>
				<v-list-item @click="showPopup = true">{{ $t("components.navigations.label.orders") }}</v-list-item>
				<v-list-item @click="showPopup = true">{{ $t("components.navigations.label.quote_requests") }}</v-list-item>
				<v-list-item @click="showPopup = true">{{ $t("components.navigations.label.favorites") }}</v-list-item>
			</v-list>

			<v-divider
				color="#E6E6EB"
				opacity="100"
			/>

			<v-list>
				<NuxtLink
					:to="`${adminPanelUrl}/dashboard`"
					external
				>
					<v-list-item class="control_items">
						<span>{{ $t("components.navigations.label.go_to_my_admin") }}</span>
						<Icon name="md-open_in_new" />
					</v-list-item>
				</NuxtLink>

				<v-list-item
					class="control_items"
					@click="logout"
				>
					<span>{{ $t("components.navigations.label.sign_out") }}</span>
					<Icon name="md-logout" />
				</v-list-item>
			</v-list>
		</v-card>
	</v-menu>
	<div
		v-else
		class="flex items-center space-x-[8px]"
	>
		<NuxtLink
			class="desktop-nav-item mr-[-1px]"
			to="/auth"
		>
			<div>{{ $t("components.navigations.label.login") }}</div>
		</NuxtLink>
		<div class="h-[20px] w-[1px] bg-neutral-200"></div>
		<NuxtLink
			class="desktop-nav-item ml-[-1px]"
			to="/auth?tab=sign_up&accountType=business"
		>
			<div>{{ $t("components.navigations.label.create_account") }}</div>
		</NuxtLink>
	</div>
	<NotAvailableForDemo
		v-model="showPopup"
		closable
		width="700"
		:has-button="false"
	/>
</template>

<script setup lang="ts">
import NotAvailableForDemo from "@/components/NotAvailableForDemo.vue";

const { adminPanelUrl } = useRuntimeConfig().public;
const { organizationProfile } = storeToRefs(useOrgStore());
const userStore = useUserStore();
const { isLoggedIn, user, userOrganizationProfile } = storeToRefs(userStore);
const { clearCartData } = useCartStore();

const showPopup = ref(false);
const userInitial = computed(() => {
	if (!user.value) return "";
	return `${user.value.first_name.charAt(0)}${user.value.last_name.charAt(0)}`;
});

const userName = computed(() => {
	if (!user.value) return "";
	return `${user.value.first_name} ${user.value.last_name}`;
});

const logout = () => {
	userStore.userLogout();
	// clear cart info
	clearCartData();
	navigateTo(`/${organizationProfile.value?.slug || ""}`);
};
</script>

<style lang="scss" scoped>
#personal_info {
	:deep(.v-list-item-title) {
		@apply text-neutral-900 font-bold;
	}
	:deep(.v-list-item-subtitle) {
		@apply text-neutral-700;
	}
}

.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
	@apply px-[24px];
}
.v-list-item {
	:deep(.v-list-item__content) {
		@apply text-neutral-900;
	}
}
.control_items {
	:deep(.v-list-item__content) {
		@apply flex items-center justify-between;
	}
}
</style>
